import {fetchGet,fetchPost} from '../../router/https'
function getCouponList(data){
    return fetchGet('/mallapp/couponuser/page',data)
}
function getShopCoupons(data){
            return fetchGet('/mallapp/couponinfo/page',data)
}
function userGetCoupon(data){
    return fetchPost('/mallapp/couponuser',data)
}
export {
    getCouponList,
    getShopCoupons,
    userGetCoupon
}