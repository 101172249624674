<template>
  <div class="confirm-order" v-if="orderInfo!=null">
    <div class="head" @touchmove.prevent>
      <img
        class="back"
        src="../../assets/img/commoditySpike/back.png"
        alt=""
        @click="$router.back()"
      />
      <div class="title">{{ orderInfo.name }}</div>
    </div>
    <div class="main" @touchmove.stop>
      <div class="warp">
        <div class="check-time" v-if="type === '0'">
          <span>{{ startTime.slice(5) }}</span>
          <span></span>
          <div>
            <p>
              <label>{{ dayNum }}晚</label>
            </p>
          </div>
          <span>{{ endTime.slice(5) }}</span>
          <span></span>
          <!-- <div class="last" @click="$router.back()">
          客房详情 <img src="../../assets/img/right.png" alt="" />
        </div> -->
        </div>
        <div class="check-time" v-if="type === '1'">
          <span
            >{{ startTime.slice(5) }}
            <span style="margin-left: 0.2rem">{{orderInfo.useStartTime}}-{{orderInfo.useEndTime}}中选{{orderInfo.roomDuration}}小时</span></span
          >

        
        
        </div>
        <div class="room-type">{{ orderInfo.name }}</div>
        <div class="tips">
          <span>
            {{ orderInfo.haveBreakfast === "1" ? "有早餐" : "无早餐" }} </span
          ><span v-if="orderInfo.measure">
            · {{ orderInfo.measure }}m<sup>2</sup>
          </span>
          <span v-if="orderInfo.bedSize"> · {{ orderInfo.bedSize }}m床</span
          ><span v-if="orderInfo.occupancy">
            · {{ orderInfo.occupancy }}人入住</span
          >
        </div>
       
        <ul class="rule" v-if="type === '0'">
          <li class="rule-list">
            <img src="../../assets/img/list-icon.png" alt="" />
            <span>{{ startTime.slice(5, 10) }} 18:00 前可免费取消</span>
          </li>
          <li class="rule-list">
            <img src="../../assets/img/list-icon.png" alt="" />
            <span>可较快确认订单</span>
          </li>
        </ul>
        <div class="warning">
          <img src="../../assets/img/bidu.png" alt="" />
          <span>订房必读</span>
        </div>
         <div class="rule-word" v-if="type === '1'">
          钟点房订单确认后，不可取消或退款
        </div>
        <ul class="room-rule" v-if="type === '0'">
          <li v-if="orderInfo.childrenPolicy">
            <div class="rule-name">儿童政策</div>
            <div class="rule-info">
              <div class="info-list">
                {{ orderInfo.childrenPolicy }}
              </div>
            </div>
          </li>
          <li v-if="orderInfo.foreignPolicy">
            <div class="rule-name">外宾政策</div>
            <div>{{ orderInfo.foreignPolicy }}</div>
          </li>
          <li v-if="orderInfo.reservePolicy">
            <div class="rule-name">预定提示</div>
            <div>{{ orderInfo.reservePolicy }}</div>
          </li>
           <li v-if="orderInfo.timePolicy">
            <div class="rule-name">入离时间</div>
            <div>{{ orderInfo.timePolicy }}</div>
          </li>
          <li v-if="orderInfo.mealPolicy">
            <div class="rule-name">膳食安排</div>
            <div>{{ orderInfo.mealPolicy }}</div>
          </li>
          <li v-if="orderInfo.petsPolicy">
            <div class="rule-name">宠物携带</div>
            <div>{{ orderInfo.petsPolicy }}</div>
          </li>
        </ul>
      </div>
      <div class="warp">
        <div class="check-in">
          <div>入住信息</div>
          <div class="room-desc" v-if="type === '1'">
            <div class="room-num">入住时段</div>

            <div class="middle" @click="show = true">
            
              <!-- {{ time + ":00" + "~" + (Number(time) + orderInfo.roomDuration + ":00") }} -->
               
               {{ time+":00" + "~" }}{{ (Number(time) + orderInfo.roomDuration>=orderInfo.useEndTime.split(':')[0])?Number(orderInfo.useEndTime.split(':')[0]):Number(time) + orderInfo.roomDuration }}:00
            </div>
            <img src="../../assets/img/right_black.png" alt="" />
          </div>

          <div class="room-desc">
            <div class="room-num">房间数量</div>
            <div class="middle">{{ roomNum }}间（每间最多住{{orderInfo.occupancy}}人）</div>
            <img
            class="imgright"
              src="../../assets/img/show.png"
              alt=""
              @click="chooseNum = !chooseNum"
              :class="{ imgAcitve: chooseNum }"
            />
          </div>
        </div>
      </div>
      <ul class="occupancy" v-show="chooseNum">
        <li
          class="occupancy-item"
          v-for="index in orderInfo.roomNum"
          :key="index"
          v-show="index < 11"
        >
          <div
            :class="{ 'occupancy-active': Number(roomNum) === Number(index) }"
            @click="(roomNum = index,couponId=null) 
          "
          >
            {{ index }}
          </div>
        </li>
      </ul>
      <div class="warp">
        <div class="check-in">
          <div class="person-box">
            <div class="person-info" v-for="item in roomNum" :key="item">
              住客姓名
              <div class="person-content">
                <input  :style="{'red':isRed&&userInfo[item]}"  v-if="item<2" type="text" v-model="userInfo[item-1]"  placeholder="每间房填写一位住客姓名">
                <input  v-show="item>1 &&!isRed" type="text"  v-model="userInfo[item-1]" placeholder="每间房填写一位住客姓名">
                <input class="red" v-show="item>1 &&isRed" type="text"  v-model="userInfo[item-1]" placeholder="每间房填写一位住客姓名">
              </div>
            </div>
            <div class="person-info">
              联系手机
              <div class="person-content">
                <span>+86</span> <input class="userphone" type="text" v-model="phone" />
              </div>
            </div>
            <div class="person-time" v-if="type === '0'">
              <span class="title">预计到店</span>
              <div class="person-content" @click="isShow()">
                <span
                  class="time"
                  style="font-size: 0.453333rem; white-space: nowrap"
                  >
                  <!-- {{ startTime.slice(5, 12) }}{{ time }}前 -->
                   {{ time.indexOf('次日')==-1? startTime.slice(5, 12)+' ': switchTime(startTime.slice(0, 12)) + ' '
                    
                       }}{{time}}前
                       
                </span>
                  <!-- (new Date((new Date(startTime.slice(0, 12).replace(/年|日|月/ig,'/')).getTime()+ 24*3600*1000)  .getDate())+"日 " + time +"前" -->

                <span
                  class="lablefix"
                  style="
                    font-size: 0.32rem;
                    word-wrap: break-word;
                    color: #999;
                    white-space: warp;
                  "
                  >房间将整晚保留</span
                >
              </div>
              <p class="content-info">
                <img src="../../assets/img/right_black.png" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
       <div class="warp">
      <div class="check-in">
        <div class="person-box">
          <div class="invoice-box">
            <div class="invoice" style="margin-right: ">发票</div>
            <div class="invoice-tips">
              <div>支持预约酒店开票（仅普票）提前预约，退房前台领取</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="warp">
      <div class="check-in">
        <div class="person-box">
             <div class="line-pay">
          <div class="pay-title">优惠券</div>
          <div
            class="pay-content"
            style="color: rgba(0, 0, 0, 0.3)"
            @click="switchPaul()"
          >
          <div v-if="couponId==null">  {{ couponUse.length }}张优惠券可用</div>
          <div v-if="couponId!==null" style="color:red;font-width:600;">  -{{ (this.allMoney- this.total  ).toFixed(2) }}￥</div>

            <img
              class="label6"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4db3f6ebbb11bca02917aa17fa658726db449c768b690480fddf47cb37455581"
            />
          </div>
        </div>
        </div>
      </div>
    </div>
   <div class="warp">
      <div class="check-in">
       <div class="person-box">
       
        <div class="line-pay">
          <div class="pay-title">在线支付</div>
          <div class="pay-content" v-if="type === '0'">
            {{ roomNum }}间{{ dayNum }}晚 共 ¥{{
              dayNum * roomNum * orderInfo.price
            }}

          </div>
          <div class="pay-content" v-if="type === '1'">
            {{ roomNum }}间 共 ¥{{ roomNum * orderInfo.price }}
          </div>
        </div>
        <span class="line"></span>
        
        <div class="line-pay" style="border: none">
          <div class="pay-title" >房费</div>
          <div class="pay-content" >¥{{ orderInfo.price }}</div>
        </div>
        <div class="bottom"> <span>优惠</span> <span>{{(this.allMoney -this.total).toFixed(2)}}</span></div>
        <div class="bottom">
          <span
            >{{
              new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              (new Date().getDate() + 1)
            }}
            {{ orderInfo.haveBreakfast === "1" ? "有" : "无" }}早餐</span
          >
          <span 
            >{{ roomNum }} x ¥{{ orderInfo.price  }}</span>
         
        </div>
      </div>
      </div>
    </div>

  <div class="warp">
    <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell clickable @click="radio = '1'">
            <template #title>
              <img class="img" src="../../assets/img/weChat-icon.png" alt />
              <span class="custom-title">微信</span>
            </template>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell clickable @click="radio = '2'">
            <template #title>
              <img class="img" src="../../assets/img/alipay-icon.png" alt />
              <span class="custom-title">支付宝</span>
            </template>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    </div>

    <div class="footer" @touchmove.prevent>
      <div class="footer-box">

      <div class="call-phone">
        <a :href="'tel:' + orderInfo.phone">
          <img src="../../assets/img/kefu.png" alt="" />
          <span>电话咨询</span>
        </a>
      </div>
      <div class="btns" v-if="type === '0'">
        <div class="btn1">
          <p>￥</p>
          <div class="total">{{ total }}</div>
          <div
            class="orginactive"
            v-if="orderInfo.giveIntegralNum && orderInfo.giveIntegral === '1'"
          >
            返平台积分{{ orderInfo.giveIntegralNum * roomNum }}
          </div>
        </div>
        <div class="btn2" @click="orderHotel()">去结算</div>
      </div>
      <div class="btns" v-if="type === '1'">
        <div class="btn1">
          <p>￥</p>
          <div class="total">{{total }}</div>
          <!--  roomNum * orderInfo.price -->
          <div
            class="orginactive"
            v-if="orderInfo.giveIntegralNum && orderInfo.giveIntegral === '1'"
          >
            返平台积分{{ orderInfo.giveIntegralNum * roomNum }}
          </div>
        </div>
        <div class="btn2" @click="orderHotel()">去结算</div>
      </div>
      </div>

    </div>

    <!-- 优惠券列表 -->
    <van-popup
      class="cop"
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding: 0.3rem; box-sizing: border-box"
    >
      <div class="box">
        <div class="title">优惠券</div>
        <img
          src="../../assets/img/zanwu.png"
          alt=""
          v-if="couponUse.length === 0"
        />
        <div class="item">
        <ul>
          <li
            v-for="(item, index) in couponUse"
            :key="item.id"
            @click="  couponId!=null&&  couponId.id-item.id==0?couponId=null :couponId = index 
            
            showList = false  "  v-show="allMoney>=item.premiseAmount"
          >
            <div class="left">
              <div class="price">
                {{ item.reduceAmount || item.discount }}
                <span v-if="item.type === '1'">元</span>
                <span v-if="item.type === '2'">折</span>
              </div>
                <!-- {{roomNum * orderInfo.price+ '' +item.premiseAmount}} -->
              <div>订单满{{ item.premiseAmount }}可用</div>
            </div>
            <div class="cop-time">
              <div class="name">{{ item.name }}</div>
              <span>{{ item.validBeginTime }} - {{ item.validEndTime }}</span>
            </div>

            <div class="button" v-show="couponId === index">
              <van-icon name="success" color="white" />
            </div>
            <div v-show="couponId !== index" class="button1"></div>
          </li>
        </ul>
        </div>
      </div>
    </van-popup>

    <van-action-sheet v-model="show">
      <div class="timearea">
        <div class="title" v-if="type === '0'">预计到店</div>
        <div class="title" v-if="type === '1'">入住时段</div>
        <ul class="time" v-if="type === '0'">
          <li
            class="time-list"
            v-for="item in timeList"
            :key="item"
            @click="(time = item), (show = false)"
          >
            {{ item }}
          </li>
        </ul>
        <ul class="time" v-if="type === '1'">
          <li
            class="time-list" 
            v-for="item in timerage"
            :key="item"
            @click="(time = item), (show = false)"
          >
            {{ item+":00" + "~" }}{{ (( (Number(item) + orderInfo.roomDuration>=orderInfo.useEndTime.split(':')[0])?Number(orderInfo.useEndTime.split(':')[0]):Number(item) + orderInfo.roomDuration )) }}:00
          </li>
        </ul>
      </div>
    </van-action-sheet>
  </div>
</template>


<script>
import { aliPay, wxPay } from "../../api/hotel/index";
import { roomData } from "../../api/roomDetail/index";
import { getCouponList } from "../../api/cop/index";
import { addRoom } from "../../api/confirmOrder/index";
export default {
  data() {
    return {
      isClick:false,
      isRed:false,
      couponId: null,
      date: "",
      isShowDate: false,
      orderInfo: null,
      roomNum: 1,
      chooseNum: false,
      couponList: [],
      showList: false,
      show: false,
      userInfo:['','','','','','','','','',''],
      timeList: [
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "次日00:00",
        "次日01:00",
        "次日02:00",
        "次日03:00",
        "次日04:00",
        "次日05:00",
        "次日06:00",
      ],
      timerage: [],
      time: this.$route.query.type == "0" ? "18:00" : "09",
      coupons: {},
      startTime: new Date(),
      endTime: "",
      dayNum: 1,
      phone: this.$store.state.userDate.phone,
      radio: "1",
      data: "",
      type: this.$route.query.type,
      
    };
  },

  methods: {
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
      console.log(index);
    },
    onExchange(code) {
      this.coupons.push(code);
      console.log(code);
    },
    switchPaul() {
      this.showList = !this.showList;
    },
    isShow() {
      this.show = true;
    },

    switchTime(val){ 
      let time = val.replace(/年|月/g,'/').replace('日','')
      time =  new Date(new Date(time).getTime()+24 * 3600 * 1000) 
      let mon = time.getMonth()+1<10?'0'+time.getMonth()+1:time.getMonth()+1
      let day = time.getDate()<10?'0'+time.getDate():time.getDate()
      return mon+'月'+day+'日'
    },

    orderHotel() {
      //  console.log(this.startTime.replace(/年|月/ig,'-').slice(0,10),"time");
      //  console.log({
      //   userId:this.$store.state.userDate.id,
      //   roomId:this.orderInfo.id,
      //   roomNum:this.roomNum,
      //   checkinDate: this.startTime.replace(/年|月/ig,'-').slice(0,10),
      //   checkoutDate:this.endTime.replace(/年|月/ig,'-').slice(0,10),
      //   duration:this.dayNum,
      //   salesPrice:this.orderInfo.price,
      //   paymentPrice:this.dayNum*this.orderInfo.price*this.roomNum,
      //   bonusPoints: this.orderInfo.giveIntegral==='1'&&this.orderInfo.giveIntegralNum*this.roomNum*this.dayNum,
      //   HotelOrderItems:{
      //     username:"张晨",
      //     roomSort:""
      //   },
      //   phone:this.$store.state.userDate.phone
      //  });
      // console.log(this.$rotue.query.startTime.replace(/年|月/ig,'-').slice(0,10));

      if(this.isClick){ 
        this.$toast('正在加载中请耐心等待...')
      }
      
  let arrList = this.phone.split('')
      if(arrList.length!==11){
        this.$toast('请输入正确的手机号')
        return
      }
  this.isRed=false
  let arr=[]
 for(let i=0 ; i<this.roomNum;i++){
   console.log(this.userInfo[i],1111);
   if(!this.userInfo[i]){
     this.isRed=true
     this.$toast('请填写完整信息')
   }
   if(this.userInfo[i]){
     arr.push({userName:this.userInfo[i],
     roomSort:i

     })
   }
 }

let timeAre
    if(this.isRed){
      return 
    }
    this.isClick=true
    let timeNum=""
    console.log(this.time,"time");
    if(this.type==='1'){

    
    if(this.time.includes('次日')){

        let timeArr = this.$route.query.startTime.replace(/年|月/ig,'/').slice(0,10)

         timeArr = new Date(new Date(timeArr).getTime()+24 * 3600 * 1000)  
         
          let mon = timeArr.getMonth()+1<10?'0' +(timeArr.getMonth()+1):timeArr.getMonth()+1
          let day = timeArr.getDate()<10 ? '0'+ timeArr.getDate() :timeArr.getDate()
         
          timeNum = timeArr.getFullYear() + '-' + mon + day +' '+ this.time.replace("次日",'')  + ":00:00"  
         console.log(999);
    }else{
 
      timeNum=this.$route.query.startTime.replace(/年|月/ig,'-').slice(0,10)+' '+(this.time) +":00:00"
      console.log(888);
    }
}else{
        if((this.time+'').length==2){

          timeAre= this.time+":00:00"
      

        }else{
      

        timeAre=(this.time +":00").replace('次日','')


        }

        if(this.time.indexOf('次日')!=-1){ 
          let time= new Date(new Date(this.$route.query.startTime.replace(/年|月/ig,'/').slice(0,10)).getTime()+24*60*60*1000)
          let mon = time.getMonth()+1<10?'0'+time.getMonth()+1:time.getMonth()+1

          let day = time.getDate()<10 ?'0'+ time.getDate():time.getDate()
          timeNum=time.getFullYear() + '-' + mon +'-'+ day+' '+(timeAre)
          console.log('ccc');
        }else{ 
          console.log('dds');

        timeNum=this.$route.query.startTime.replace(/年|月/ig,'-').slice(0,10)+' '+(timeAre)

        }
}


     addRoom({
        userId: this.$store.state.userDate.id,
        roomId: this.orderInfo.id,
        roomNum: this.roomNum,
        couponUserId: this.couponId!=null?this.couponUse[this.couponId].id : null,
        checkinDate:
          this.$route.query.type == 0
            ? this.startTime.replace(/年|月/gi, "-").slice(0, 10)
            : this.startTime.replace(/年|月/gi, "-").slice(0, 10) +
              " " +
              this.time,
        checkoutDate: this.endTime.replace(/年|月/gi, "-").slice(0, 10),
        duration: this.dayNum,
        salesPrice: this.orderInfo.price,
        paymentPrice: this.total, // this.type==0?this.dayNum * this.orderInfo.price * this.roomNum :this.orderInfo.price * this.roomNum,
        bonusPoints:
          this.orderInfo.giveIntegral === "1"
            ? this.orderInfo.giveIntegralNum * this.roomNum * this.dayNum
            : 0,
        hotelOrderItems: arr,
        // {
        //   username: this.userInfo,
        //   roomSort: "",
        // },
        phone: this.phone,
        roomType: this.$route.query.type,
        estimateTime: timeNum
      }).then((res) => {
      
        if(res.data.code==1){ 
          this.$toast.fail('暂无房间')
          return
        }
        if (res.data.code === 0) {
          let data = {
            outTradeNo: res.data.data,
            pamentPrice:
              this.$route.query.type === "0"
                ? this.dayNum * this.orderInfo.price * this.roomNum
                : this.roomNum * this.orderInfo.price,
          };
          
          
          // if( this.total == 0 ){ 

          //   this.$toast.success('下单成功')
          //   this.$goBack()
          //   return
          // }
          if (this.radio === "2") {

            this.submitAlipay(data);

          } else {
           
            this.getWxPay(res.data.data);
          }
         
        }
      });
    },
    submitAlipay(form) {
      aliPay(form).then((res) => {

          if( !res.data){ 

            this.$toast.success('下单成功')
            this.$goBack()
            return
          }
          
          try {
         
            window.webkit.messageHandlers.alipay.postMessage(res.data)
            
          } catch (error) {
             window.android.alipay(res.data);
             
          }
        this.isClick = false
        
      });
    },
    //微信支付
    getWxPay(data) {

      wxPay({ orderId: data, tradeType: "APP" }).then((res) => {

          if(res.data.code != 0 ) { 
          this.isClick = false
            this.$toast(res.data.msg)
            return
          }

          if( !res.data.data ){ 

            this.$toast.success('下单成功')
            this.$goBack()
            return
          }
        this.data = res.data

        try{

      
          window.webkit.messageHandlers.wechatPay.postMessage(
            JSON.stringify(res.data.data)
          );
         }catch(error){
     
          window.android.wxpay(
            res.data.data.appId,
            res.data.data.partnerId,
            res.data.data.prepayId,
            res.data.data.nonceStr,
            res.data.data.timeStamp,
            res.data.data.packageValue,
            res.data.data.sign
          );
        
       
            
        }
        this.isClick = false

      });
    },
    closePage(){
      
      this.$goBack()
      
    }
    
    
  },
  created() {
    
    console.log(this.time)
    window.closePage=this.closePage
    if (!this.$route.query.id) {
      return;
    }
    this.userInfo[0]=this.$store.state.userDate.nickName
    this.roomNum = Number(this.$route.query.roomNum) ;
    this.dayNum = this.$route.query.dayNum;
    this.startTime = this.$route.query.startTime;
    this.endTime = this.$route.query.endTime;
    roomData(this.$route.query.id).then((res) => {
      if (res.data.code === 0) {
        this.orderInfo = res.data.data;
        let spuId= this.$route.query.id
        getCouponList({spuIds:spuId,shopId:this.orderInfo.shopId}).then((res) => {
          console.log(res, "优惠券");
          if (res.data.code === 0) {
            this.couponList = res.data.data.records.filter((item) => {
              return item.shopId === this.orderInfo.shopId;
            });
          }
        });
          this.timerage=[]
          console.log(this.orderInfo.useStartTime,2222);
          if(!this.orderInfo.useStartTime) return

            
          
             let time = this.$route.query.endTime.slice(5,6).split('月')
             
        for( let i = Number(this.orderInfo.useStartTime.split(':')[0]) ;i <Number(this.orderInfo.useEndTime.split(':')[0]);i++ ){
               
                if((time[0] - new Date().getMonth()-1 ) && time[1] - new Date().getDate()){ 

                    if(i>=new Date().getHours()+1){
                          if(i<10){
                          this.timerage.push('0'+i)

                          }else{
                          this.timerage.push(i+'')

                          }
                    }
                   


                }else{ 
                
                     if(i<10){
                          this.timerage.push('0'+i)

                          }else{
                          this.timerage.push(i+'')

                          }


                }

        }

        let week = ['周日', '周一','周二','周三','周四','周五','周六']
        // console.log( this.$route.query.startTime.slice(-2).trim(),'useStartTime');
        // console.log( week[new Date().getDay()],'useStartTime');

        
        if( (this.$route.query.type - 0 )&& (this.$route.query.startTime.slice(-2).trim() == week[new Date().getDay()])) { 
              let arrList = []
            this.timerage.forEach(item => { 
             
              if( new Date().getHours()-Number(item) <0){ 
                arrList.push(item)
                console.log(arrList,'arrList');
              }
              
            })
            
          this.timerage = arrList
        
          
        }

        this.time=  this.timerage[0] ??  '00'
        console.log(this.time);
        console.log(this.timerage,1111);
      }
    });
  },
  computed: {
    total() {
      let all=0
      if(this.$route.query.type==1){ 
          all = this.roomNum * this.orderInfo.price
      }else{ 

          all = this.dayNum * this.roomNum * this.orderInfo.price
        
      }
      
      console.log(this.couponUse[this.couponId]);
      if (this.couponUse[this.couponId]) {
        
        if (this.couponUse[this.couponId].type === "2") {

            
            all >= this.couponUse[this.couponId].premiseAmount &&
            (all = (all * (this.couponUse[this.couponId].discount) / 10));
          

           
        }

        if (this.couponUse[this.couponId].type === "1") {
          all >=this.couponUse[this.couponId].premiseAmount &&
            (all = all - this.couponUse[this.couponId].reduceAmount);
            
             console.log(this.couponUse[this.couponId],444);
        }
       

      }

      
      return all;
    },
    allMoney(){ 
          console.log(this.dayNum ,this.roomNum,this.orderInfo.price)

          if( this.$route.query.type==1){ 
            return this.roomNum * this.orderInfo.price
          }
      return this.dayNum *this.roomNum * this.orderInfo.price
    },

    couponUse(){ 
      
      
        return this.couponList.filter(item=>{ 

           return (this.allMoney>=item.premiseAmount)

        })
      
    },
    
  },
  mounted() {
    this.setRem();
   
    window.addEventListener("resize", this.setRem);
  },
 
};
</script>

<style lang="less" scoped>
.confirm-order::-webkit-scrollbar {
  display: none;
}
.confirm-order {
  background-color: #f6f6f6;
  height:100%;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  .head {
    box-sizing: border-box;
    z-index: 100;
    position: fixed;
    padding: 0.266667rem 0.933333rem 0.266667rem 0.266667rem;
    border-bottom: 0.013333rem solid #ccc;
    width: 100%;
    background-color: white;
    .title {
      font-size: 0.453333rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: center;
    }
    .back {
      float: left;
      width: 0.666667rem;
      height: 0.64rem;
      // margin-left: 0.266667rem;
    }
  }
  .main::-webkit-scrollbar {
    display: none;
  }
  .main {
    overflow: auto;
    padding: 1.21rem 0.266667rem 2.5rem;
    box-sizing: border-box;
    height: 100%;
   
    .warp {
      background-color: white;
      padding: 0.266667rem;
      margin: 0.466667rem 0;
      border-radius: 0.133333rem;
    }
    .check-time {
      border-bottom: 1px solid #eee;
      display: flex;
      font-size: 0.32rem;
      color: #333;
      font-family: PingFangSC-Semibold, PingFang SC;

      align-items: center;
      padding-bottom: 0.56rem;
      span {
        margin-right: 0.133333rem;
        display: flex;
        align-items: center;
      }

      div {
        width: 1.2rem;
        margin-right: 0.133333rem;
        position: relative;
        height: 0.013333rem;
        background-color: #ff5200ff;
        p {
          position: absolute;
          width: 0.933333rem;
          height: 0.4rem;
          background: #ffffff;
          border-radius: 21px;
          border: 1px solid #ff5200;
          top: -0.2rem;
          left: 50%;
          transform: translate(-50%);

          label {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 0.32rem;
            transform: scale(0.83);
            color: #ff5200;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .last {
        height: 100%;
        background-color: white;
        width: 1.413333rem;
        text-align: center;
        line-height: 0.453333rem;
        flex: 1;
        text-align: right;
        color: #ff5200ff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        img {
          width: 0.453333rem;
          height: 0.453333rem;
          margin-left: 0.133333rem;
        }
      }
    }
    .room-type {
      font-size: 0.48rem;
      font-weight: 600;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 50px;
      color: #333333;
    }
    .tips {
      color: #999999ff;
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      margin-bottom: 0.133333rem;
    }
    .rule-word {
      font-size: 0.32rem;

      span {
        color: #ff5200;
      }
    }
    .rule {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.24rem;
      border-bottom: 1px solid #eee;
      padding-bottom: 0.266667rem;
      .rule-list {
        color: #ff5200ff;
        font-size: 0.32rem;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        img {
          width: 0.453333rem;
          height: 0.453333rem;
          margin-right: 0.08rem;
        }
      }
    }
    .warning {
      display: flex;
      align-items: center;
      margin-bottom: 0.266667rem;
      img {
        width: 0.453333rem;
        height: 0.453333rem;
        margin-right: 0.08rem;
      }
      span {
        color: #ff5200ff;
        font-size: 0.32rem;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
      }
    }
    .room-rule {
      font-size: 0.32rem;
      color: #999999ff;
      li:nth-last-of-type(1) {
        margin-bottom: 0;
      }
      li {
        display: flex;
        margin-bottom: 0.266667rem;

        .rule-name {
          color: #333333ff;
          width: 48px;
          text-align: center;
          text-align: left;
          white-space: nowrap;
          margin-right: 0.266667rem;
        }
        .info-list {
          display: flex;
          white-space: pre-wrap;
          .icon {
            width: 0.32rem;
            text-align: left;
          }
        }
      }
    }
    .warp {
      .check-in {
        .title {
          font-size: 0.373333rem;
          color: #333333ff;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
        .room-desc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.453333rem;
          height: 1.493333rem;
          .room-num {
            color: #999999ff;
          }
          .middle {
            color: #333333ff;
            flex: 1;
            padding-left: 0.56rem;
            color: #333;
          }
          .imgright{
            transform: rotate(180deg) ;

          }
          img {
            
            width: 0.373333rem;
            height: 0.373333rem;
            transition: 0.2s linear;
          }
          .imgAcitve {
            transform: rotate(0deg) !important;
          }
        }
      }

      .check-in {
        .title {
          font-size: 0.373333rem;
          color: #333333ff;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
        .room-desc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.453333rem;
          .room-num {
            color: #999999ff;
          }
          .middle {
            color: #333333ff;
            flex: 1;
            padding-left: 0.56rem;
            color: #333;
          }
          
          img {
            width: 0.373333rem;
            height: 0.373333rem;
          }
          .imgright{
            transition: 0.2s linear;

          }
          .imgAcitve {
            transform: rotateZ(180deg);
          }
        }
        .occupancy {
          margin-bottom: 0.266667rem;
          display: flex;
          flex-wrap: wrap;

          .occupancy-item {
            margin-right: 0.066667rem;
            width: 1.68rem;
            height: 0.906667rem;
            div {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid transparent;
            }
            .occupancy-active {
              border: 2px solid #ff5200;
              border-radius: 0.133333rem;

              background: #fff8ef;
              margin-bottom: 0.133333rem;
            }
          }
          .occupancy-item:nth-of-type(5n) {
            margin-right: 0px;
          }
        }
        .person-box {
          .person-info {
            display: flex;
            height: 1.493333rem;
            align-items: center;
            font-size: 0.453333rem;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #99999f;
            overflow: hidden;
            white-space: nowrap;
            box-sizing: border-box;
            .person-content {
              margin-left: 0.56rem;
              color: #333;
              display: flex;
              align-items: center;
              flex: 1;
              .userphone{
                margin-left: .266667rem;
              }
            input{
              font-weight: .373333rem;
              width: 100%;
            }
              .content-info {
                font-size: 0.32rem;
                display: flex;
                align-items: center;
                flex: 1;
                text-align: right;
                justify-content: flex-end;
                color: #999999ff;
                img {
                  width: 0.373333rem;
                  height: 0.373333rem;
                  margin-left: 0.426667rem;
                }
              }
           
            }
          }
          .person-time {
            padding-top: 0.4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            height: 1.493333rem;
            display: flex;
            box-sizing: border-box;
            .title {
              font-size: 0.453333rem;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #99999f;
              box-sizing: border-box;
              padding-right: 0.373333rem;
              white-space: nowrap;
              font-weight: 400 !important;
            }
            .person-content {
              display: flex;
              display: inline;
              margin-left: 0.2rem;
              flex: 1;
            }
          
            .content-info {
              padding-top: 0.066667rem;
              img {
                width: 0.373333rem;
                height: 0.373333rem;
              }
            }
          }
        }
        .invoice-box {
          height: 1.493333rem;
          display: flex;
          align-items: center;
          margin-bottom: 0.266667rem;
          .invoice {
            width: 1.813333rem;

            margin-right: 0.56rem;
            color: #99999f;
            font-size: 0.453333rem;
          }
          .invoice-tips {
            font-size: 0.373333rem;
          }
        }
        .line-pay {
          display: flex;
          align-items: center;
          height: 1.493333rem;
          .pay-title {
            font-size: 0.453333rem;
            color: #333333ff;
          }
          .pay-content {
            flex: 1;
            font-size: 0.373333rem;
            color: black;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
              width: 0.186667rem;
              height: 0.346667rem;
              margin-left: 0.266667rem;
            }
          }
        }
        .bottom {
          display: flex;
          font-size: 0.373333rem;
          color: #333;
          justify-content: space-between;
          align-items: center;
          padding-bottom: .266667rem;
        }
      }
    }
    .occupancy {
      margin-bottom: 0.266667rem;
      display: flex;
      flex-wrap: wrap;
      // padding: 0 .133333rem;

      .occupancy-item {
        margin-right: 0.066667rem;
        width: 1.6rem;
        height: 0.906667rem;
        display: block;
        background-color: white;
        margin: 0.133333rem;
        box-sizing: border-box;
        border-radius: 0.133333rem;
        font-weight: 600;
        div {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid transparent;
        }
        .occupancy-active {
          border: 2px solid #ff5200;
          border-radius: 0.133333rem;

          background: #fff8ef;
          margin-bottom: 0.133333rem;
        }
      }
      // .occupancy-item:nth-of-type(5n) {
      //   margin-right: 0px;
      // }
    }
   

    .cop::-webkit-scrollbar {
      display: none;
    }
    
    .img {
      width: 0.64rem !important;
      height: 0.64rem !important;
    }
    .van-cell__title {
      font-size: 0.373333rem;
      display: flex;
      align-items: center !important;
      margin-right: 0.266667rem !important;
    }
    .custom-title {
      margin-left: 0.266667rem;
    }
  }
  .timearea::-webkit-scrollbar{
    display: none;
  }
  .timearea {
    padding: 0.533333rem 0.426667rem 5rem;
    .title {
      color: #333;
      margin-bottom: 0.266667rem;
      font-size: 0.426667rem;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    .time {
      height: 100%;
      width: 100%;
      font-size: 0.453333rem;
      // display: flex;
      // flex-wrap: wrap;
      .time-list {
        width: 2.18rem;
        height: 0.933333rem;
        background: #edeff5;
        margin-right: 0.133333rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #999999;
        float: left;
        margin-bottom: .266667rem;
      }
    }
    .time-list:nth-of-type(4n) {
      margin-right: 0;
    }
  }
  .line{
    display: block;
    height: 1px;
    background-color: #eee;
  }
   .footer {
     position: fixed;
     bottom: 0;
     left: 0;
     width: 100%;
     background-color: white;
   
    
     box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      .footer-box{
         padding:  .266667rem;
           display: flex;
        align-items: center;
        width: 100%;
      .call-phone {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 0.64rem;
            height: 0.64rem;
            margin-bottom: 0.133333rem;
          }
          span {
            display: block;
            font-size: 0.32rem;
            transform: scale(0.83);
            color: black;
          }
        }
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 1.066667rem;
        overflow: hidden;
        width: 8rem;
        color: #999999ff;
        font-size: 0.32rem;
        box-sizing: border-box;
        .btn1 {
          width: 5.68rem;
          height: 100%;
          background-color: #313131ff;
          color: white;
          display: flex;
          align-items: flex-end;
          border-radius: 0.266667rem 0 0 0.266667rem;
          padding-left: 0.133333rem;
          box-sizing: border-box;
          p {
            margin-bottom: 0.2rem;
          }
          .total {
            box-sizing: border-box;
            color: white;
            font-size: 0.693333rem;
            height: 1.05rem;
          }
          div {
            margin-top: 0.266667rem;
            color: #999999ff;
          }
          span {
            text-decoration: line-through;
          }
        }
        .btn2 {
          border-radius: 0 0.266667rem 0.266667rem 0;

          width: 1.92rem;
          height: 100%;
          background-image: linear-gradient(to right, #ff7200, #ff3c00);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          font-size: 0.373333rem;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      }

    }
    /deep/ [class*=van-hairline]::after{
      border:transparent solid 0
    }
    /deep/ .van-cell{
      padding: .266667rem 0 ;
    }
    .red::-webkit-input-placeholder{
      color: red;
    }
}
.cop {
      .box::-webkit-scrollbar {
        display: none;
      }
      .box {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title {
          text-align: center;
          font-size: 0.426667rem;
          font-weight: 600;
          margin-bottom: 0.666667rem;
        }
      }
      img {
        width: 100%;
      }
      // ul::-webkit-scrollbar {
      //   display: none;
      // }
      .item::-webkit-scrollbar {
        display: none;
      }
      .item{ 
        // -webkit-overflow-scrolling: touch;
         flex: 1;
        //  overflow:auto;
        // overflow-y: scroll;
        // box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        overflow: auto;

      }
      ul {
        margin-bottom: 0.4rem;
        li {
          padding: 0.266667rem;
          display: flex;
          align-items: center;
          font-size: 0.373333rem;
          border: 1px solid #eaeaea;
          border-radius: 0.266667rem;
          margin-bottom: .4rem;
          .left {
            color: red;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .price {
              font-size: 0.746667rem;
              span {
                font-size: 0.426667rem;
              }
            }
          }
          .cop-time {
            padding-left: 0.533333rem;
            // padding-top: 0.4rem;
            padding-right: .4rem;
            width: 50%;
            .name {
              font-size: 0.4rem;
              font-weight: 600;
              margin-bottom: 0.15rem;
              span {
                transform: scale(0.83);
                font-size: .32rem;

              }
            }
          }
          .button {
            height: 0.426667rem;
            width: 0.426667rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: red;
            margin-right: .133333rem;
          }
          .button1 {
            height: 0.4rem;
            width: 0.4rem;
            border-radius: 50%;
            border: 1px solid #ccc;

           
          }
        }
      }
    }
</style>